import { createGlobalStyle } from "styled-components";
import "./global.css";
import Router from "./components/Router";

const GlobalStyles = createGlobalStyle`
  html {
    --color-text: black;
    --color-background: white;
    --color-primary: #ef3f40;
  }
`;

function App({ children }) {
  return (
    <>
      <GlobalStyles />
      <Router />
      {children}
    </>
  );
}

export default App;
