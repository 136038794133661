import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  position: relative;
  display: block;
  color: var(--darkgrey);
`;
// Card Content
const Content = ({ children }) => <Wrapper>{children}</Wrapper>;
export default Content;
