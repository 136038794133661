import React from "react";
import styled from "styled-components/macro";

import { ReactComponent as Logo } from "../../logo-dark.svg";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import { useRouteMatch, NavLink, Link, useLocation } from "react-router-dom";
import constants from "../../constants";

const NavigationBarWrapper = styled.nav`
  position: relative;
  height: 64px;
  width: 100vw;
`;

const NavigationBarBackground = styled.div`
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: cover;
  background-repeat: none;
  background-color: purple;
`;

const TextLink = styled.a`
  color: var(--color-primary);
  font-weight: var(--font-weight-medium);
`;

const NavigationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
`;
const NavigationItem = styled.li`
  padding-left: 10px;
`;

const NavigationBar = ({ brand = true }) => (
  <NavigationBarWrapper>
    <NavigationList>
      {constants.routes.map(({ path, component, title }, i) => (
        <NavigationItem key={i}>
          {/* Hide the first nav item if brand is true. */}
          {i === 0 ? (
            brand ? (
              <NavLink to={path}>
                <Logo style={{ height: "60px" }} />
              </NavLink>
            ) : null
          ) : (
            <TextLink>
              <NavLink to={path}>{title}</NavLink>
            </TextLink>
          )}
        </NavigationItem>
      ))}
    </NavigationList>
  </NavigationBarWrapper>
);

export default NavigationBar;
