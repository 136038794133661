import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 50px 0 0;
  background: #fff;

  @media (max-width: 650px) {
    padding-top: 5vw;
  }
`;
// Card Group
const Group = ({ children }) => <Wrapper>{children}</Wrapper>;
export default Group;
