import React from "react";
import styled from "styled-components";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import HomeHeader from "../components/Header/Home";
import Main from "../components/Site/Main";
import FullWidth from "../components/Site/FullWidth";
import Centered from "../components/Site/Centered";
import CardGroup from "../components/Card/Group";
import Service from "../components/Card/Service";
import constants from "../constants";

const Article = styled.article``;

const Paragraph = styled.p``;

const Footer = styled.footer``;

const HomeScreen = () => {
  return (
    <>
      <header>
        <NavigationBar brand={false} />
        <HomeHeader imgUrl={constants.home.banner.imgUrl} />
      </header>
      <Main>
        <FullWidth>
          <Centered>
            <div style={{ overflowX: "hidden" }}>
              <Article style={{ paddingTop: "30px" }}>
                <Paragraph>
                  Surety Logistics is a nontraditional broker. We match the
                  shipper directly with our asset-based carriers. Reliability is
                  key. Each of our locally owned carriers are carefully selected
                  to ensure our shipping customers receive exceptional service.
                  What makes Surety Logistics different from any other
                  brokerage? We provide a personal touch that our drivers get to
                  know our valued shipping customers directly. Our goal is to
                  understand the shipper’s needs and fulfill those needs through
                  our reliable services. We bring the neighborhood carrier to
                  you with the perks and resources of a large trucking company.
                </Paragraph>
                <CardGroup>
                  {constants.services.map((service) => (
                    <Service key={service.name} {...service}></Service>
                  ))}
                </CardGroup>
                {/* <Services /> */}
              </Article>
            </div>
          </Centered>
        </FullWidth>
      </Main>
      <Footer></Footer>
    </>
  );
};

export default HomeScreen;
