import React from "react";
import styled from "styled-components";

import Archive from "./Archive";

const Wrapper = styled.div`
  ${Archive} {
    padding-top: 0;
    padding-bottom: 0;
    color: var(--darkgrey);
    background: #fff;
    opacity: 1;
  }
`;

const NoImage = ({ children }) => <Wrapper>{children}</Wrapper>;
export default NoImage;
