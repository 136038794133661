import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
`;

const Centered = ({ children }) => <Wrapper>{children}</Wrapper>;
export default Centered;
