import React from "react";
import styled from "styled-components/macro";

import Home from "./Home";
import Archive from "./Archive";
import NoImage from "./NoImage";
const Wrapper = styled.h2`
  z-index: 10;
  margin: 0;
  padding: 5px 0;
  font-size: 2.1rem;
  line-height: 1.4em;
  font-weight: 400;
  opacity: 0.8;

  ${Home} & {
    font-size: 2.2rem;
    font-weight: 300;
    text-align: center;
  }
  ${Archive} & ${NoImage} {
    color: var(--midgrey);
    opacity: 1;
  }
  @media (max-width: 500px) {
    ${Home} {
      font-size: 1.8rem;
    }
  }
`;
// Header Description
const Description = ({ children }) => <Wrapper>{children}</Wrapper>;
export default Description;
