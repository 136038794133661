import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import constants from "../constants";
import ErrorScreen from "../screens/error-screen";

const RouteWithSubroutes = (route) => {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};

const RouterComponent = () => (
  <Router>
    <Switch>
      {constants.routes.map((route, i) => (
        <RouteWithSubroutes key={i} {...route} />
      ))}
      <Route path="*" component={ErrorScreen} />
    </Switch>
  </Router>
);

export default RouterComponent;
