import React from "react";
import styled from "styled-components";
import Navbar from "../components/NavigationBar/NavigationBar";
import ArchiveHeader from "../components/Header/Archive";

import Main from "../components/Site/Main";
import MemberCard from "../components/Card/Member";
import MemberCardGroup from "../components/Card/Group";

import constants from "../constants";
import FullWidth from "../components/Site/FullWidth";
import Centered from "../components/Site/Centered";

const TheTeamScreen = () => {
  return (
    <>
      <header>
        <Navbar />
        <ArchiveHeader
          title="The Team"
          description="The people who make it all possible"
        ></ArchiveHeader>
      </header>
      <Main>
        <FullWidth>
          <Centered>
            <div style={{ overflowX: "hidden" }}>
              <MemberCardGroup>
                {constants.teamMembers.map((member, idx) => (
                  <MemberCard key={idx} {...member} />
                ))}
              </MemberCardGroup>
            </div>
          </Centered>
        </FullWidth>
      </Main>
      <footer></footer>
    </>
  );
};
export default TheTeamScreen;
