import React from "react";

import Navbar from "../components/NavigationBar/NavigationBar";

import ArchiveHeader from "../components/Header/Archive";
import constants from "../constants";
import Main from "../components/Site/Main";
import CardGroup from "../components/Card/Group";
import Card from "../components/Card/Member";
import FullWidth from "../components/Site/FullWidth";
import Centered from "../components/Site/Centered";
import Carrier from "../components/Card/Carrier";
const CarriersScreen = () => {
  const Nav = () => <Navbar />;

  return (
    <>
      <header>
        <Nav />
        <ArchiveHeader
          title="Carriers"
          description="Meet some of our carriers."
        />
      </header>
      <Main>
        <FullWidth>
          <Centered>
            <div style={{ overflowX: "hidden" }}>
              <CardGroup>
                {constants.carriers.map((carrier) => (
                  <Carrier {...carrier} />
                ))}
              </CardGroup>
              <h2 style={{ textAlign: "center" }}>
                We service thousands of trucks from 80+ carriers nationwide!
              </h2>
            </div>
          </Centered>
        </FullWidth>
      </Main>
      <footer></footer>
    </>
  );
};
export default CarriersScreen;
