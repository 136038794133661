import React from "react";
import styled from "styled-components/macro";

import Image from "./Image";
import Content from "./Content";
import Header from "./Header";
import Title from "./Title";
const PhoneWrapper = styled.div``;
const Phone = ({ number }) => (
  <PhoneWrapper hidden={number === null}>
    <a href={`tel:${number}`} hidden={!number}>
      Call {number}
    </a>
  </PhoneWrapper>
);
const EmailWrapper = styled.div``;

const Email = ({ address }) => (
  <EmailWrapper hidden={!address}>
    <a
      href={`mailto:${address}?subject=SuretyLogistics.com`}
      hidden={address === null}
    >
      Email {address}
    </a>
  </EmailWrapper>
);

const ImageWrapper = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 3px;
`;

const Wrapper = styled.div`
  position: relative;
  flex: 1 1 301px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 0 40px;
  padding: 0 20px 40px;
  min-height: 220px;
  border-bottom: 1px solid color-mod(var(--lightgrey) l(+12%));
  background-size: cover;

  /* Adjust some margins for smaller screens */
  @media (max-width: 1170px) {
    margin-bottom: 5vw;
  }

  @media (max-width: 650px) {
    margin-bottom: 5vw;
  }
`;
const Member = ({ name, imgUrl, description, phone, email, ...rest }) => (
  <Wrapper>
    <ImageWrapper>
      <img
        style={{
          width: "100%",
          height: "250px",
          "--background": "var(--lightgrey) no-repeat center center",
          objectFit: "cover",
        }}
        srcSet={`${imgUrl}/300.jpg, 300w, ${imgUrl}/600.jpg, 600w, ${imgUrl}/900.jpg, 900w`}
        sizes="(max-width: 1000px) 400px, 700px"
        loading="lazy"
        src={`${imgUrl}/600.jpg`}
        alt={name}
      />
    </ImageWrapper>

    <Content>
      <Header></Header>
      <Title>{name}</Title>
      <p>{description}</p>
      <Email address={email} />
      <Phone number={phone} />
    </Content>
  </Wrapper>
);
export default Member;
