import React from "react";
import styled from "styled-components/macro";

import { ReactComponent as LightLogoSvg } from "../../logo-light.svg";
import { ReactComponent as DarkLogoSvg } from "../../logo-dark.svg";

const Wrapper = styled.div`
  text-align: center;
`;
const Logo = () => (
  <Wrapper>
    <LightLogoSvg
      style={{
        maxHeight: "70px",
      }}
    />
  </Wrapper>
);
export default Logo;
