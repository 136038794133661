import React from "react";
import styled from "styled-components/macro";

import Image from "./Image";
import Content from "./Content";
import Header from "./Header";
import Title from "./Title";
import Description from "./Description";

const Wrapper = styled.div`
  position: relative;
  flex: 1 1 301px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 0 40px;
  padding: 0 20px 40px;
  min-height: 220px;
  border-bottom: 1px solid color-mod(var(--lightgrey) l(+12%));
  background-size: cover;

  /* Adjust some margins for smaller screens */
  @media (max-width: 1170px) {
    margin-bottom: 5vw;
  }

  @media (max-width: 650px) {
    margin-bottom: 5vw;
  }
`;
const Service = ({ name, description, imgUrl }) => (
  <Wrapper>
    <div
      style={{
        position: "relative",
        display: "block",
        overflow: "hidden",
        borderRadius: "3px",
      }}
    >
      <img
        style={{
          width: "100%",
          height: "250px",
          "--background": "var(--lightgrey) no-repeat center center",
          objectFit: "cover",
        }}
        srcSet={`${imgUrl}/300.jpg, 300w, ${imgUrl}/600.jpg, 600w, ${imgUrl}/900.jpg, 900w`}
        sizes="(max-width: 1000px) 400px, 700px"
        loading="lazy"
        src={`${imgUrl}/600.jpg`}
        alt={name}
      />
    </div>
    {/* <Image imgUrl={imgUrl} title={name} /> */}
    <Content>
      <Header></Header>
      <Title>{name}</Title>
      <Description>{description}</Description>
    </Content>
  </Wrapper>
);
export default Service;
