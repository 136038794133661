import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  padding: 0 5vw;
`;

const FullWidth = ({ children }) => <Wrapper>{children}</Wrapper>;
export default FullWidth;
