import React from "react";
import styled from "styled-components/macro";

// Header Imports
import Background from "./Background";
import Content from "./Content";
import Title from "./Title";
import Logo from "./Logo";
import Description from "./Description";

// Global Constants
import constants from "../../constants";

// Styles
const Wrapper = styled.div`
  z-index: 1000;
`;

const Home = () => (
  <Wrapper>
    <Background imgUrl={constants.home.banner.imgUrl}>
      <Content>
        <Logo />
        <Description>
          Moving freight through strategic partnerships.
        </Description>
      </Content>
    </Background>
  </Wrapper>
);

export default Home;
