import HomeScreen from "./screens/home-screen";
import AboutScreen from "./screens/about-screen";
import CarriersScreen from "./screens/carriers-screen";
import ContactUsScreen from "./screens/contact-us.screen";
import TheTeamScreen from "./screens/the-team.screen";
import ErrorScreen from "./screens/error-screen";
// const ASSETS_URL = "http://static-suretylogistics.com.s3-website-us-west-2.amazonaws.com/assets";
const ASSETS_URL = "https://www.suretylogistics.com/assets";
const CARRIERS_URL = `${ASSETS_URL}/carriers`;
const SERVICES_URL = `${ASSETS_URL}/services`;
const BANNERS_URL = `${ASSETS_URL}/banners`;
const MEMBERS_URL = `${ASSETS_URL}/team-members`;

const buildPlaceholderUrl = name => `https://via.placeholder.com/150.png?text=${name}`;
const constants = {
  site: {
    title: "Surety Logistics",
    logo: `${ASSETS_URL}/surety-logistics/logo.png`,
  },
  routes: [
    {
      path: "/",
      component: HomeScreen,
      exact: true,
      title: "Surety Logistics",
    },
    {
      path: "/about",
      component: AboutScreen,
      exact: true,
      title: "About",
    },
    {
      path: "/the-team",
      component: TheTeamScreen,
      exact: true,
      title: "The Team",
    },
    {
      path: "/carriers",
      component: CarriersScreen,
      exact: true,
      title: "Carriers",
    },
    // {
    //   path: "/contact-us",
    //   component: ContactUsScreen,
    //   exact: true,
    //   title: "Contact Us",
    // },
  ],
  home: {
    banner: {
      imgUrl: `${BANNERS_URL}/blue-volvo-country`,
    },
    // `${BANNERS_URL}/blue-volvo-country/666.jpg 666w, ${BANNERS_URL}/blue-volvo-country/1333.jpg 1333w, ${BANNERS_URL}/blue-volvo-country/2000.jpg 2000w`,
  },
  carriersListTitle: "Meet our carriers 🚚",
  carriers: [
    {
      name: "Pointdirect Transport, Inc.",
      description: "",
      imgUrl: `${CARRIERS_URL}/pointdirect`,
      // `${CARRIERS_URL}/pointdirect/300.jpg, 300w, ${CARRIERS_URL}/pointdirect/600.jpg, 600w, ${CARRIERS_URL}/pointdirect/900.jpg, 900w`,
      webUrl: "https://point-direct.com",
    },
    {
      name: "Surety Transportation",
      description: "",
      imgUrl: `${CARRIERS_URL}/surety-transportation`,
      // `${CARRIERS_URL}/surety-transportation/300.jpg, 300w, ${CARRIERS_URL}/surety-transportation/600.jpg, 600w, ${CARRIERS_URL}/surety-transportation/900.jpg, 900w`,
      webUrl: "http://www.suretytransportation.com",
    },
    {
      name: "Quantum Freight",
      description: "",
      imgUrl: `${CARRIERS_URL}/quantum-freight`,
      // `${CARRIERS_URL}/quantum-freight/300.jpg, 300w, ${CARRIERS_URL}/quantum-freight/600.jpg, 600w, ${CARRIERS_URL}/quantum-freight/900.jpg, 900w`,
      webUrl: "http://www.quantumfreight.com",
    },
  ],
  services: [
    {
      name: "Freight Brokerage",
      description: "We match shipping customers with asset-based carriers.",
      imgUrl: `${SERVICES_URL}/freight-brokerage`,
      // `${SERVICES_URL}/freight-brokerage/300.jpg 300w, ${SERVICES_URL}/freight-brokerage/600.jpg 600w, ${SERVICES_URL}/freight-brokerage/900.jpg 900w`,
    },
    {
      name: "Equipment Leasing",
      description: "New equipment is reliable equipment. Our carriers are in the most up to date and current vehicles.",
      imgUrl: `${SERVICES_URL}/equipment-leasing`,
      //  `${SERVICES_URL}/equipment-leasing/300.jpg 300w, ${SERVICES_URL}/equipment-leasing/600.jpg 600w, ${SERVICES_URL}/equipment-leasing/900.jpg 900w`,
    },
    {
      name: "24-Hour Breakdown Services",
      description: "Rest assured. Our services will help your freight be delivered on time.",
      imgUrl: `${SERVICES_URL}/24-hour-breakdown-service`,
      // `${SERVICES_URL}/24-hour-breakdown-servie/300.jpg 300w, ${SERVICES_URL}/24-hour-breakdown-service/600.jpg 600w, ${SERVICES_URL}/24-hour-breakdown-service/900.jpg 900w`,
    },
  ],
  teamMembers: [
    {
      name: "Sarah Lounds",
      description: `
      I'm a founding member of Surety with more than 25 years of experience in the trucking industry. During this time, I have been busy establishing successful relationships with hardworking partners and sharing in the success that comes from it.
      `,
      imgUrl: `${MEMBERS_URL}/default`,
      email: "sarah@suretylogistics.com",
      phone: "385-246-1382",
      // srcset: `${MEMBERS_URL}/default/300.jpg 300w, ${MEMBERS_URL}/default/600.jpg 600w, ${MEMBERS_URL}/default/900.jpg 900w`,
    },
    {
      name: "Chris Martinson",
      description: `
      Prior to joining the Surety team, I was a General Manager within a Fortune 200 transportation company.  I have 26+ years of sales experience.
      My firsthand knowledge in building long-standing customer and carrier relationships will be beneficial in our long-term growth plan.
      `,
      imgUrl: `${MEMBERS_URL}/martinson-c`,
      email: "chris@suretylogistics.com",
      phone: "612-709-7089",
      // srcset: `${MEMBERS_URL}/mortinson-c/300.jpg 300w, ${MEMBERS_URL}/mortinson-c/600.jpg 600w, ${MEMBERS_URL}/mortinson-c/900.jpg 900w`,
    },
    {
      name: "Scott Vosburg",
      description: `
      As one of the newest members, I am very excited to be joining the Surety team. For the last 26 years, I have been honing my skills within the logistics industry at C.H Robinson.
      Throughout these years I have successfully worked in a variety of roles from sales to management.  
      I look forward to leveraging my expertise and experience, promoting continued growth within our organization.
      `,
      imgUrl: `${MEMBERS_URL}/vosburg-s`,
      email: "scott@suretylogistics.com",
      phone: "612-860-3712",
      // srcset: `${MEMBERS_URL}/default/300.jpg 300w, ${MEMBERS_URL}/default/600.jpg 600w, ${MEMBERS_URL}/default/900.jpg 900w`,
    },
    {
      name: "Danielle Worthley",
      description: `
      I have been in Sales/Customer Service for over 20 years, servicing a wide variety of industries.  In 2005 I joined CR England and worked in their logistics division, where I met Sarah.  In Oct 2006 I decided to take a break from my career and stay home to raise our 2 year old son and baby on the way, we were soon surprised with our daughter the following year.  Over the next several years I went back to school and in 2013 graduated from WGU with a Bachelors Degree in Business Marketing and also became a licensed Real Estate Agent in the State of Utah.  In 2015 I reunited with Sarah and jumped back into the trucking industry running large fleets with a couple different companies until we started Surety in 2017.
      In my spare time I continue to sell homes in Utah and love the change of pace and different challenges it brings.  The rest my free time is spent either at a wrestling tournament, lacrosse game, football game or coaching my daughters competition soccer team.  We live on a small farm in northern Utah and enjoy raising our goats and riding our horses.`,
      imgUrl: `${MEMBERS_URL}/worthley-d`,
      email: "danielle@suretylogistics.com",
      // srcset: `${MEMBERS_URL}/worthley-d/300.jpg 300w, ${MEMBERS_URL}/worthley-d/600.jpg 600w, ${MEMBERS_URL}/worthley-d/900.jpg 900w`,
    },
    {
      name: "Joel LeRoy",
      description: `
      I’ve been in transportation industry for 25 years. I began my career with Flying J as the director of new business of development.  My job was to integrate fuel, fuel cards, factoring, insurance, leasing, within the Flying J customer base. My passion has always been to help the little guy compete in the increasing competitive market. I started my own company 11 years ago with the objective to achieve strategic relationships to help the little guy succeed. 
      `,
      imgUrl: `${MEMBERS_URL}/leroy-j`,
      // srcset: `${MEMBERS_URL}/leroy-j/300.jpg 300w, ${MEMBERS_URL}/leroy-j/600.jpg 600w, ${MEMBERS_URL}/leroy-j/900.jpg 900w`,
    },
  ],
  api: {
    url: "https://865d0ta7s3.execute-api.us-west-2.amazonaws.com/dev/",
    endpoints: {
      contactUs: "/contact-us",
    },
  },
};

export default constants;
