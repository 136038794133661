import React from "react";
import styled from "styled-components/macro";

import Home from "./Home";
import Archive from "./Archive";
import NoImage from "./NoImage";
const Wrapper = styled.div`
  position: relative;
  ${Home} & {
    padding: 5vw 3vw 6vw;
  }
  ${Archive} & {
    position: relative;
    align-items: stretch;
    padding: 12vw 0 20px;
    min-height: 200px;
    max-height: 600px;
  }
  ${Archive} ${NoImage} & {
    padding: 5vw 0 10px;
    border-bottom: 1px solid color-mod(var(--lightgrey) l(+12%));
  }
  @media (max-width: 500px) {
    ${Archive} {
      flex-direction: column;
      align-items: center;
      min-height: unset;
    }
    ${Archive} ${NoImage} & {
      padding: 12vw 0 20px;
    }
  }
`;
// Header Content
const Content = ({ children }) => <Wrapper>{children}</Wrapper>;
export default Content;
