import React from "react";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import ArchiveHeader from "../components/Header/Archive";
import styled from "styled-components";
import constants from "../constants";
import Main from "../components/Site/Main";
import FullWidth from "../components/Site/FullWidth";
import Centered from "../components/Site/Centered";

const AboutScreen = () => {
  return (
    <>
      <header className="site-archive-header">
        <NavigationBar brand={true} />
        <ArchiveHeader title="About" description="This is our story">
          <h1>About</h1>
          <h2>This is our story</h2>
        </ArchiveHeader>
      </header>
      <Main>
        <FullWidth>
          <Centered>
            <div style={{ overflowX: "hidden" }}>
              <article>
                <header className="post-full-header"></header>

                <section className="post-full-content">
                  <div className="post-content">
                    <p>
                      Surety Logistics has been in business for 4-years. Our
                      company began with the unique idea of helping locally
                      owned carriers find business in a competitive field of
                      work. We wanted our shipping customers to experience
                      outstanding customer service. Our dedicated team has
                      worked hard over the years to make this happen. We have
                      successfully brought work to neighborhood carriers and
                      provided excellent service to our shippers. We love to see
                      businesses thrive and become successful.{" "}
                    </p>
                  </div>
                </section>

                {/* <figure className="post-full-image">
            <img src={constants.banners[1]} alt={"About"} />
          </figure> */}
              </article>
            </div>
          </Centered>
        </FullWidth>

        {/* <ArchiveHeader srcset={constants.banners[1]} /> */}
        {/* <div className="inner posts">
          <div className="post-feed">
            {constants.carriers.map((carrier) => (
              <PostCard {...carrier} />
            ))}
          </div>
        </div> */}
      </Main>
      <footer></footer>
    </>
  );
};
export default AboutScreen;
