import React from "react";
import styled from "styled-components/macro";
import Content from "./Content";
const Wrapper = styled.div`
  margin: 0 0 0.4em;
  line-height: 1.15em;
  transition: color 0.2s ease-in-out;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  font-size: 2.2rem;
  @media (max-width: 500px) {
    font-size: 1.9rem;
  }
`;
// Card Title
const Title = ({ children }) => <Wrapper>{children}</Wrapper>;
export default Title;
