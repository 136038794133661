import React from "react";
import styled from "styled-components/macro";

import Background from "./Background";
import Content from "./Content";
import Title from "./Title";
import Description from "./Description";

import NoImage from "./NoImage";

import constants from "../../constants";
const Wrapper = styled.div.attrs(({ imgUrl }) => ({
  imgUrl: imgUrl || "",
}))`
  position: relative;
  align-items: stretch;
  padding: 12vw 0 20px;
  min-height: 200px;
  max-height: 600px;

  padding-top: ${(props) => (props.imgUrl ? 0 : "inherit")};
  padding-bottom: ${(props) => (props.imgUrl ? 0 : "inherit")};
  color: var(--darkgrey);
  background: #fff;
  opacity: 1;
  /* isolation: isolate; */
`;

const Archive = ({ imgUrl, title, description }) => (
  <Wrapper>
    {imgUrl ? (
      <Background imgUrl={imgUrl}>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>
      </Background>
    ) : (
      <NoImage>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>
      </NoImage>
    )}
  </Wrapper>
);

export default Archive;
