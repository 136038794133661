import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 56em;
  color: var(--midgrey);
  font-family: Georgia, serif;

  @media (max-width: 500px) {
    font-size: 1.6rem;
  }
`;
const Description = ({ children }) => <Wrapper>{children}</Wrapper>;
export default Description;
