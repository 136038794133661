import React, { useState } from "react";

import Navbar from "../components/NavigationBar/NavigationBar";
import Main from "../components/Site/Main";

// Header Imports
import ArchiveHeader from "../components/Header/Archive";

import ContactUsForm from "../components/Forms/ContactUsForm";

import constants from "../constants";
import FullWidth from "../components/Site/FullWidth";
import Centered from "../components/Site/Centered";

const ErrorScreen = () => {
  const [loading, setLoading] = useState(true);

  const Nav = () => <Navbar />;

  return (
    <>
      <header>
        <Nav />
        <ArchiveHeader
          title="404 Error"
          description="Oops. Something went wrong."
        ></ArchiveHeader>
      </header>
      <Main>
        <FullWidth>
          <Centered>
            <h5>Check your URL or try back later.</h5>
          </Centered>
        </FullWidth>
      </Main>
      <footer></footer>
    </>
  );
};
export default ErrorScreen;
