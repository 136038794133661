import React from "react";
import styled from "styled-components/macro";

import Home from "./Home";

const Wrapper = styled.div.attrs(({ imgUrl }) => ({
  imgUrl: imgUrl,
}))`
  position: relative;
  margin-top: 64px;
  padding-bottom: 12px;
  color: #fff;
  background: color-mod(var(--darkgrey) l(-5%)) no-repeat center center;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.imgUrl + "/2000.jpg"});

  @media (max-width: 1000px) {
    background-image: url(${(props) => props.imgUrl + "/1000.jpg"});
    background-image: -webkit-image-set(
      url(${(props) => props.imgUrl + "/1000.jpg"}),
      1000w,
      url(${(props) => props.imgUrl + "/2000.jpg"}),
      2000w
    );
    background-image: image-set(
      url(${({ imgUrl }) => imgUrl + "/1000.jpg"}),
      1000w,
      url(${({ imgUrl }) => imgUrl + "/2000.jpg"}),
      2000w
    );
  }
  @media (max-width: 600px) {
    background-image: url(${(props) => props.imgUrl + "/600.jpg"});
    background-image: -webkit-image-set(
      url(${(props) => props.imgUrl + "/600.jpg"}),
      600w,
      url(${(props) => props.imgUrl + "/1000.jpg"}),
      1000w
    );
    background-image: image-set(
      url(${({ imgUrl }) => imgUrl + "/600.jpg"}),
      600w,
      url(${({ imgUrl }) => imgUrl + "/1000.jpg"}),
      1000w
    );
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: rgba(0, 0, 0, 0.18);
    /* Hide background if no children are present */
    display: ${(props) => (props.imgUrl ? "block" : "none")};
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    display: block;
    height: 140px;
    background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    /* Hide background if no children are present */
    display: ${(props) => (props.imgUrl ? "block" : "none")};
  }

  ${Home} & {
    margin-top: 0;
  }
`;

// Header Background
const Background = ({ imgUrl, children }) => (
  <Wrapper imgUrl={imgUrl}>{children}</Wrapper>
);
export default Background;
