import React from "react";
import styled from "styled-components/macro";

// const Wrapper = styled.div`
//   position: relative;
//   display: block;
//   overflow: hidden;
//   border-radius: 3px;
// `;

const Wrapper = styled.img`
  /* display: block; */
  /* width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  border-radius: 100%; */
  width: 100%;
  /* height: 250px; */
  background: var(--lightgrey) no-repeat center center;
  object-fit: cover;
`;

// const Img = styled.img``;
const Image = ({ imgUrl, title }) => (
  <Wrapper
    srcSet={`${imgUrl}/300.jpg, 300w, ${imgUrl}/600.jpg, 600w, ${imgUrl}/900.jpg, 900w`}
    sizes="(max-width: 1000px) 400px, 700px"
    loading="lazy"
    src={`${imgUrl}/600.jpg`}
    alt={title}
  />
);
export default Image;
