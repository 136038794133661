import React from "react";
import styled from "styled-components";

import Home from "./Home";
import Archive from "./Archive";
const Wrapper = styled.h1`
  z-index: 10;
  margin: 0 0 0 -2px;
  padding: 0;
  font-size: 5rem;
  line-height: 1em;
  font-weight: 600;
  ${Home} & {
    font-size: 5.5rem;
    text-align: center;
  }
  @media (max-width: 500px) {
    ${Home} & {
      font-size: 4.2rem;
    }
    ${Archive} & {
      font-size: 4.2rem;
      text-align: center;
    }
  }
`;
// Header Title
const Title = ({ children }) => <Wrapper>{children}</Wrapper>;
export default Title;
