import React from "react";
import styled from "styled-components";

const OuterMain = styled.div``;
const InnerMain = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const StyledMain = styled.main`
  z-index: 100;
  flex-grow: 1;
`;

const Main = ({ children }) => (
  <Wrapper>
    <StyledMain>{children}</StyledMain>
  </Wrapper>
);

export default Main;
